import React from "react"
import Layout from '../../components/layout'
import Section from '../../components/section'
import Banner from '../../components/banner'
import Container from '../../components/container'
import Typography from '../../components/typography'
import Seo from '../../components/seo';

export default function EngineeringDesigns(props) {
  return (
    <Layout {...props}>
      <Seo 
        title="Engineering Design" 
        description="Preparation of various engineering designs including architectural designs for construction, renovation or for extension of any establishments." 
      />
      <Section>
        <Banner 
          src="/img/sample-hero-image-1.jpg"
          header="Engineering Design"
        />
      </Section>
      <Section>
        <Container>
          <Typography component="p">
            Preparation of various engineering designs including architectural designs for construction, renovation or for
            extension of any establishments.
          </Typography>
        </Container>
      </Section>
    </Layout>
  )
}
